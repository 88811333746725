import Vue from "vue";
import _ from "lodash";
import { mapState } from "vuex";
import { showFeature, showFeatureAnonymous } from "@/api/launchDarkly";

Vue.mixin({
  computed: {
    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId,
      selectedAccountId: state => state.selectedAccountId,
      selectedAccountDomain: state => state.selectedAccountDomain
    })
  },
  methods: {
    // to determine if we show or hide the requested feature
    showFeature(
      featureName,
      { ac_id = null, sp_id = null, domain_id = null } = {}
    ) {
      let user = Vue.auth.user();
      const payload = {
        key: user.user_id,
        email: user.email,
        name: user.display_name,
        feature: featureName,
        custom: {
          ac_id: ac_id || this.selectedAccountId,
          sp_id: sp_id || this.selectedServiceProviderId,
          domain_id: domain_id || _.get(this.selectedAccountDomain, "domain_id")
        }
      };
      return new Promise(resolve => {
        showFeature(payload)
          .then(response => {
            if (response.data.message === "success") {
              resolve(response.data.flag_state);
            } else {
              resolve(false);
            }
          })
          .catch(error => {
            console.error(error);
            //  using resolve false as a default behaviour of LD here instead of reject,
            //  as any of the calls to this method dont use catch block
            resolve(false);
          });
      });
    },
    showFeatureAnonymous(featureName, state = true) {
      const payload = {
        feature: featureName,
        custom: {
          anonymous: state
        }
      };
      return new Promise((resolve, reject) => {
        showFeatureAnonymous(payload)
          .then(response => {
            if (response.data.message === "success") {
              resolve(response.data.flag_state);
            } else {
              resolve(false);
            }
          })
          .catch(error => {
            reject(error);
            resolve(false);
          });
      });
    }
  }
});
