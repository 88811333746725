<template>
  <div></div>
</template>

<script>
import "@/mixins/ldCheck";
export default {
  name: "GainSightPX",
  props: {
    anonymous: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  methods: {
    initializeGainSightPX() {
      let self = this;
      (function(n, t, a, e, co) {
        let i = "aptrinsic";
        (n[i] =
          n[i] ||
          function() {
            (n[i].q = n[i].q || []).push(arguments);
          }),
          (n[i].p = e);
        n[i].c = co;
        let r = t.createElement("script");
        (r.async = !0), (r.src = a + "?a=" + e);
        let c = t.getElementsByTagName("script")[0];
        c && c.parentNode.insertBefore(r, c);
      })(window, document, self.getGainSightPxUrl(), self.getGainSightPxKey());
    },
    async isTrackingEnabled() {
      if (this.anonymous === true) {
        return await this.showFeatureAnonymous(
          this.$getConst("GAIN_SIGHT_PX_ANALYTICS")
        );
      }
      return await this.showFeature(this.$getConst("GAIN_SIGHT_PX_ANALYTICS"));
    },
    getGainSightPxKey() {
      return process.env.VUE_APP_GAINSIGHTPX_KEY || "";
    },
    getGainSightPxUrl() {
      return process.env.VUE_APP_GAINSIGHTPX_URL;
    }
  },
  mounted() {
    if (this.isTrackingEnabled()) {
      this.initializeGainSightPX();
    }
  }
};
</script>
